import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, keywords, title }) {
    return (
        <StaticQuery
            query={detailsQuery}
            render={(data) => {
                const metaDescription = description || data.site.siteMetadata.description;
                const allKeywords = data.site.siteMetadata.keywords;
                return (
                    <Helmet
                        htmlAttributes={{
                            lang,
                        }}
                        title={data.site.siteMetadata.title}
                        titleTemplate={`${data.site.siteMetadata.title}`}
                        meta={[
                            {
                                name: `description`,
                                content: metaDescription,
                            },
                            {
                                property: `og:title`,
                                content: data.site.siteMetadata.title,
                            },
                            {
                                property: `og:description`,
                                content: metaDescription,
                            },
                            {
                                property: `og:type`,
                                content: `website`,
                            },
                            {
                                property: `og:site_name`,
                                content: data.site.siteMetadata.siteName,
                            },
                            {
                                property: `og:locale`,
                                content: data.site.siteMetadata.locale,
                            },
                            {
                                property: `og:url`,
                                content: data.site.siteMetadata.url,
                            },
                            {
                                property: `og:image`,
                                content: data.site.siteMetadata.ogImageUrl,
                            },
                            {
                                name: `twitter:card`,
                                content: `summary`,
                            },
                            {
                                name: `twitter:creator`,
                                content: data.site.siteMetadata.author,
                            },
                            {
                                name: `twitter:title`,
                                content: data.site.siteMetadata.title,
                            },
                            {
                                name: `twitter:description`,
                                content: metaDescription,
                            },
                        ]
                            .concat(
                                allKeywords.length > 0
                                    ? {
                                          name: `keywords`,
                                          content: allKeywords.join(`, `),
                                      }
                                    : []
                            )
                            .concat(meta)}
                    />
                );
            }}
        />
    );
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    keywords: [],
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
    query DefaultSEOQuery {
        site {
            siteMetadata {
                title
                description
                author
                keywords
                url
                locale
                siteName
                ogImageUrl
            }
        }
    }
`;
