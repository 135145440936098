import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontFamily, fontWeight, textAlign, lineHeight, letterSpacing } from 'styled-system';
import { base, themed } from '../base';

const TextWrapper = styled('p')(base, fontFamily, fontWeight, textAlign, lineHeight, letterSpacing, themed('Text'));

const Text = ({ content, children, ...props }) => (
    <TextWrapper {...props}>
        {content}
        {children}
    </TextWrapper>
);

export default Text;

Text.propTypes = {
    content: PropTypes.string,
    as: PropTypes.string,
    mt: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    mb: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    fontFamily: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    fontWeight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    textAlign: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    lineHeight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    letterSpacing: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    ...base.propTypes,
};

Text.defaultProps = {
    as: 'p',
    mt: 0,
    mb: '1rem',
};
